import React from 'react';
import './Card.css';
import icon from './icon-heartbeat.svg';
import moment from 'moment';

export const BirthdayCard = props => {
    let now = moment()
    let todaysBirthdays = props.people.filter(person => {
        if (moment(person.birthday).year(now.startOf('day').year()).diff(now, 'days') === 0) {
            return true
        }
        return false
    })

    return (
        <div className={'card card--xlarge card--' + (todaysBirthdays.length > 0 ? 'birthday' : 'success')}>
            <i className="card__icon">
                <img src={icon} alt="" />
            </i>
            <div className="card__title">{todaysBirthdays.length === 0 ? 'Anstehende ' : ''}Geburtstage</div>
            <div className="list-entries">
            {props.people.map(person => {
                let formattedDate = moment(person.birthday).format('DD.MM.YYYY');
                return (
                    <div className="list-entry" key="person.name">
                        <div className="list-entry__date">{person.name}</div>
                        <div className="list-entry__title">{formattedDate}</div>
                    </div>
                )
            })}
            </div>
        </div>
    )
}