import React from 'react';
import moment from 'moment'
import {BirthdayCard} from "../Components/BirthdayCard";

export class Birthday extends React.Component {

    get upcomingBirthdays() {
        return this.props.data.filter(person => {
            let now = moment();
            now.startOf('day');

            let birthDay = moment(person.birthday).year(now.year());
            let birthDayNextYear = moment(person.birthday).year(now.year() +1);

            if (birthDay >= now) {
                return birthDay.diff(now, 'days') <= 7;
            }
            return birthDayNextYear.diff(now, 'days') <= 7;
        })
    }

    get card() {
        if (this.upcomingBirthdays.length > 0) {
            return (<BirthdayCard people={this.upcomingBirthdays} />)
        }
        return null;
    }

    render() {
        return (
            <>
                {this.card}
            </>
        )
    }
}