import React from 'react';
import {config} from "../config";
import {AbstractModule} from "./AbstractModule";
import {NotesCard} from "../Components/NotesCard";
import ReactInterval from "react-interval";

export class Notes extends AbstractModule {
    get apiUrl() {
        return `${config.defaults.heartbeatApi}/notes`
    }

    componentDidMount() {
        this.setState({
            status: 'default',
            showAction: localStorage.getItem('managementMode') === null
        })
        this.update()
    }

    handleResponse(response) {
        this.setState({
            notes: response
        })
    }

    get card() {
        return (<NotesCard
            addCallback={(noteText) => {this.add(noteText)}}
            deleteCallback={(noteId) => {this.delete(noteId)}}
            markAsDoneCallback={(note) => {this.markAsDone(note)}}
            {...this.state} />);
    }

    add(noteText) {
        fetch(this.apiUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({noteText})
        })
            .then(() => {
                this.update();
            });
    }

    delete(noteId) {
        fetch(this.apiUrl, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({noteId})
        })
            .then(() => {
                this.update();
            });
    }

    markAsDone(note) {
        fetch(this.apiUrl, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                id: note.id,
                text: note.text,
                done: 'true'
            })
        })

            .then(() => {
                this.update();
            });
    }

    render() {
        return (
            <>
                <ReactInterval timeout="5000" enabled="true" callback={() => this.update()}/>
                {this.card}
            </>
        )
    }
}
