import React from 'react';
import './Card.css';
import iconTag from './icon-tag.svg';
import iconBranch from './icon-git.svg';

export const PipelineCard = props => {
     return (
        <div className={'card card--xlarge card--' + props.status}>
            <i className="card__icon">
                <img src={props.tag ? iconTag : iconBranch} alt="" />
            </i>
            <div className="card__title">{props.name}</div>
            <div className="card__spacer"></div>
            <div className="card__url">{(new Date(props.date)).toLocaleString('de-DE')}</div>
            <div className="card__url">{props.branch}</div>
            <div className="card__status">{props.status}</div>
        </div>
    )
}