import React from "react";
import "./Card.css";
import moment from "moment";

export const AbsencesCard = ({ absences }) => {
  const formattedDate = (date) => moment(date).format("DD.MM.YYYY");

  const calendarWeek = (startDate, endDate) => {
    if (moment(startDate).isoWeek() === moment(endDate).isoWeek()) {
      return moment(startDate).isoWeek();
    } else {
      return `${moment(startDate).isoWeek()} - ${moment(endDate).isoWeek()}`;
    }
  };

  const shortName = (name) => {
    // console.log(name)
    let shortName = "";
    switch (name) {
      case "Fahimreza Nasirzadeh":
        shortName = "Fahim";
        break;
      case "Miran Cumurija":
        shortName = "Miran";
        break;
      case "Fabian Stein":
        shortName = "Fabian";
        break;
      case "Mihriban Dalmis":
        shortName = "Mi";
        break;
      case "Alexander Reichel":
        shortName = "Alex";
        break;
      case "Marco Schiffmann":
        shortName = "Marco";
        break;
      case "Maximilian Voigt":
        shortName = "Max";
        break;
      case "Maria Katharina Käser":
        shortName = "Maria";
        break;
      case "David Vogt":
        shortName = "Dex";
        break;
      default:
        shortName = "";
    }
    return shortName;
  };

  const isAbsence = (start, end) => {
    if (
      new Date().getTime() >= new Date(start).getTime()
    ) {
      return "is-absence";
    } else {
      return "";
    }
  };
  let filteredAbsences;

  if (absences) {
    const sortedByStartDate = absences.sort(
      (a, b) =>
        new Date(a.attributes.start_date).getTime() -
        new Date(b.attributes.start_date).getTime()
    );

    filteredAbsences = sortedByStartDate.filter((absence) => {
      return !(
        absence.attributes.created_by === "David Vogt" &&
        new Date(absence.attributes.start_date).getDay() === 5 &&
        absence.attributes.time_off_type.attributes.name === "Gleitzeit" &&
        absence.attributes.days_count === 1
      );
    });
  }

  const formatter = new Intl.DateTimeFormat("de", { month: "short" });
  const twoMonthLater = new Date().setMonth(new Date().getMonth() + 2);

  return (
    <div className={"card card--xlarge card--delayed card--absences"}>
      <div className="card__title">
        Abwesenheiten bis {new Date(twoMonthLater).getDate()}.{" "}
        {formatter.format(twoMonthLater)}
      </div>
      <div className="list-entries">
        <table>
          <thead>
            <tr>
              <th>Wer</th>
              <th>Ab</th>
              <th>Bis</th>
              <th className="card--absences_center">Tage</th>
              <th className="card--absences_center">KW</th>
              <th>Art Abwesenheit</th>
            </tr>
          </thead>
          <tbody>
            {filteredAbsences &&
              Object.entries(filteredAbsences).map(([key, absence]) => {
                return (
                  <tr
                    key={key}
                    style={{
                      fontSize: `${20 - key / 2}px`,
                      fontWeight: `${800 - key * 25}`,
                    }}
                    className={isAbsence(
                      absence.attributes.start_date,
                      absence.attributes.end_date
                    )}
                  >
                    {/* {key} */}
                    <td className="card--absences_name">
                      {shortName(absence.attributes.created_by)}
                    </td>
                    <td className="card--absences_start">
                      {formattedDate(absence.attributes.start_date)}
                    </td>
                    <td className="card--absences_end">
                      {formattedDate(absence.attributes.end_date)}
                    </td>
                    <td className="card--absences_days card--absences_center">
                      {absence.attributes.days_count}
                    </td>
                    <td className="card--absences_kw card--absences_center">
                      {calendarWeek(
                        absence.attributes.start_date,
                        absence.attributes.end_date
                      )}
                    </td>
                    <td className="card--absences_type">
                      {absence.attributes.time_off_type.attributes.name}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
