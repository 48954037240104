export const config = {
    "defaults": {
        "heartbeatApi": "https://monitor.bembel.punkt.hosting/api",
        //"heartbeatApi": "http://localhost:3001/api", // FOR DEVELOPMENT
        "gitlabUrl": "https://git.punkt.de/api/v4/projects/",
        "gitlabApiToken": "glpat-26qEysYFxGhz8vtHULmU",

    },
    "monitors": [
        {
            "Abwesendheiten": [
                {
                    "name": "Abwesendheiten",
                    "type": "absences"
                }
            ],
            "Notizen": [
                {
                    "name": "Notizen",
                    "type": "notes"
                }
            ],
            "Daily": [
                {
                    "name": "Daily",
                    "type": "daily"
                }
            ],
            "Geburtstage": [
                {
                    "name": "Anstehende Geburtstage",
                    "type": "birthday",
                    "data": [
                        {"name": "Alex", "birthday": "1991-04-15"},
                        {"name": "Ali", "birthday": "1996-04-13"},
                        {"name": "Dex", "birthday": "1989-05-30"},
                        {"name": "Fabian", "birthday": "1988-02-03"},
                        {"name": "Fahim", "birthday": "1998-04-19"},
                        {"name": "Mi", "birthday": "1989-01-16"},
                        {"name": "Marco", "birthday": "1987-03-30"},
                        {"name": "Maria", "birthday": "1995-12-18"},
                        {"name": "Max", "birthday": "1993-11-15"},
                        {"name": "Miran", "birthday": "1988-07-23"}
                    ]
                }
            ]
        },
        {
            "Erste Nachhilfe": [
                {
                    "name": "erna-deploy-live",
                    "type": "gitlab",
                    "tag": /^[0-9]+\.[0-9]+\.[0-9]+$/,
                    "id": 1071
                },
                {
                    "name": "ErNa Monitoring",
                    "type": "monitoring",
                    "branch": "master",
                    "id": 1161
                },
            ]
        },
        {
            "Erste Kinderbetreuung": [
                {
                    "name": "ekb-deploy-live",
                    "type": "gitlab",
                    "tag": /^[0-9]+\.[0-9]+\.[0-9]+$/,
                    "id": 1295
                },
                {
                    "name": "Ekb Monitoring",
                    "type": "monitoring",
                    "branch": "master",
                    "id": 1386
                },
            ]
        },
        {
            "Leaseplan": [
                {
                    "name": "leaseplan-deploy-live",
                    "type": "gitlab",
                    "tag": /^[0-9]+\.[0-9]+\.[0-9]+$/,
                    "id": 1546
                },
                {
                    "name": "LEP Monitoring",
                    "type": "monitoring",
                    "branch": "master",
                    "id": 1377
                },
            ]
        },
        {
            "Siemens": [
                {
                    "name": "SIK-deploy-live",
                    "type": "gitlab",
                    "tag": /^[0-9]+\.[0-9]+\.[0-9]+$/,
                    "id": 1051
                },
                {
                    "name": "SIK Monitoring",
                    "type": "monitoring",
                    "branch": "master",
                    "id": 1318
                },
            ]
        },
        {
            "BHE": [
                {
                    "name": "bhe-deploy-live",
                    "type": "gitlab",
                    "tag": /^[0-9]+\.[0-9]+\.[0-9]+$/,
                    "id": 1220
                },
                {
                    "name": "BHE Monitoring",
                    "type": "monitoring",
                    "branch": "master",
                    "id": 1306,
                    "interval": 60
                }
            ]
        },
        {
            "LBS Microsite": [
                {
                    "name": "Microsite deploy-live",
                    "type": "gitlab",
                    "tag": /^[0-9]+\.[0-9]+\.[0-9]+$/,
                    "id": 1399
                },
                {
                    "name": "Microsite latest",
                    "type": "gitlab",
                    "branch": "main",
                    "id": 1399
                },
            ]
        },
        {
            "Monitor": [
                {
                    "name": "bembel-node-monitor",
                    "type": "gitlab",
                    "branch": "main",
                    "id": 1283
                },
            ]
        }
    ]
}
