import React from 'react';
import {AbstractModule} from "./AbstractModule";
import {PipelineCard} from "../Components/PipelineCard";
import {config} from "../config";
import moment from "moment";

var parser = require('cron-parser');

export class Gitlab extends AbstractModule {

    get apiUrl() {
        let url = `${config.defaults.gitlabUrl}${this.props.id}/pipelines?ref=${this.props.branch}&per_page=1`;

        if (this.props.tag) {
            url = `${config.defaults.gitlabUrl}${this.props.id}/pipelines?scope=tags&per_page=1`;
        }

        return `${config.defaults.heartbeatApi}/status?url=${encodeURIComponent(url)}&apiToken=${encodeURIComponent(config.defaults.gitlabApiToken)}`
    }

    async getPipelineSchedule () {
        const url = `${config.defaults.gitlabUrl}${this.props.id}/pipeline_schedules`;
        const apiCallUrl = `${config.defaults.heartbeatApi}/status?url=${encodeURIComponent(url)}&apiToken=${encodeURIComponent(config.defaults.gitlabApiToken)}`;
        return fetch(apiCallUrl)
    }

    async handleResponse(response) {
        if (this._isMounted) {
            return;
        }
        if (response.body === undefined || response.body.length === 0) {
            return;
        }
        let data = response.body[0];

        let status = data['status'];
        status = status === 'waiting_for_resource' ? 'pending' :  status;

        let scheduledDate = '';

        if (this.props.type === 'monitoring') {
            const nextScheduledRun = await this.getPipelineSchedule().then(schedules => schedules.json())
                .then(schedules => {
                    if (Array.isArray(schedules.body)) {
                        const schedule = schedules.body[0];
                        const interval = parser.parseExpression(schedule.cron);
                        return moment(interval.next().toISOString());
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            if (!nextScheduledRun) {
                status = 'error'
            } else {
               scheduledDate = nextScheduledRun.format('D.M.YYYY, HH.mm.ss');
                if (moment().diff(nextScheduledRun, 'minutes') > 15 && status !== 'running') {
                    status = 'delayed';
                }
            }
            
        }
        this.setState({
            status: status,
            date: Date.parse(data['updated_at']),
            scheduledDate: scheduledDate,
            branch: data['ref']
        });
    }

    get card() {
        return (<PipelineCard {...this.props} {...this.state} />)
    }
}