import {Gitlab} from "../Modules/Gitlab";
import {Birthday} from "../Modules/Birthday";
import {Notes} from "../Modules/Notes";
import {Daily} from "../Modules/Daily";
import {Absences} from '../Modules/Absences';

export const componentMap = {
    "gitlab": Gitlab,
    "monitoring": Gitlab,
    "birthday": Birthday,
    "notes": Notes,
    "daily": Daily,
    "absences": Absences
};
