import React, { useState, useEffect } from 'react';

export const Daily = () => {
    const [card, setCard] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            const time = new Date();
            if (time.getUTCHours() >= 7 && time.getMinutes() >= 25 && time.getUTCHours() < 8){
                setCard(
                    <div className='card card--xlarge card--pending'>
                        <div className="card__title">Daily Fragen</div>
                        <div className="card__status">
                            <ol>
                                <li>
                                    Was steht bei mir heute an?
                                </li>
                                <li>
                                    Was ist meine größte Herausforderung?
                                </li>
                                <li>
                                    Welche Termine habe ich heute?
                                </li>
                            </ol>
                        </div>
                    </div>        
                )
            } else {  
                setCard(null)
            }
        }, 1000);
    
        return () => clearInterval(interval);
    }, []);
    
    return (card)
}