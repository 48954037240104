import React from 'react';
import './Card.css';
import icon from './icon-heartbeat.svg';

export const NotesCard = props => {
    return (
        <div className={'card card--xlarge card--' + props.status}>
            <i className="card__icon">
                <img src={icon} alt="" />
            </i>

            <div className="card__title">Notes</div>
            <div className="card__spacer"></div>
            <div className="card__url">
                {props.notes?.map(note => (
                    <div className={note.done ? 'card__note card__note--done' : 'card__note'} key={note.id}>
                        <div className="note__text">{note.text}</div>
                        {note.done
                            ? <button className="delete" onClick={() => {props.deleteCallback(note.id)}}>Delete</button>
                            : <button onClick={() => {props.markAsDoneCallback(note)}}>Done</button>
                        }
                    </div>
                ))}
            </div>
            <div className="card__status">{props.statusCode}</div>
            {props.showAction &&
                <div className="card__action">
                    <form onSubmit={() => {props.addCallback(document.querySelector('#noteText').value);}}>
                        <input id="noteText" />
                        <button type="submit">+</button>
                    </form>
                </div>
            }
        </div>
    )
}
