import React from "react";
import { config } from "../config";
import { AbstractModule } from "./AbstractModule";
import { AbsencesCard } from "../Components/AbsencesCard";
import ReactInterval from "react-interval";

export class Absences extends AbstractModule {
  get apiUrl() {
    return `${config.defaults.heartbeatApi}/absences`;
  }

  handleResponse(response) {
    this.setState({
      absences: response.data,
    });
  }

  get card() {
    return <AbsencesCard {...this.state} />;
  }

  render() {
    return (
      <>
        <ReactInterval
          timeout="5000"
          enabled="true"
          callback={() => this.update()}
        />
        {this.card}
      </>
    );
  }
}
