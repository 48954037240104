import React from 'react';
import './App.css';
import {componentMap} from "./Utilities/ComponentMapping";
import {config} from "./config";

class App extends React.Component {

    constructor(props) {
        super(props);

        let notes = [], absences = [], monitorings = [], pipelines = [], projects = [];

        for (let project of config.monitors) {
            projects.push(project);
            for (let monitor of Object.values(project)) {
                notes = [...notes, ...monitor.filter(el => el.type === 'notes')];
                absences = [...absences, ...monitor.filter(el => el.type === 'absences')];
                monitorings = [...monitorings, ...monitor.filter(el => el.type === 'monitoring')];
                pipelines = [...pipelines, ...monitor.filter(el => el.type === 'gitlab')];
            }
        }
        this.state = {notes, absences, monitorings, pipelines, projects, managementMode: this.managementMode}
    }

    getCard(item) {
        const Component = componentMap[item.type];
        return React.createElement(Component, {...item, key: item.name});
    }

    get cards() {
        if (this.state.managementMode) {
            return [
                this.state.notes.map(card => this.getCard(card)),
                this.state.absences.map(card => this.getCard(card)),
                this.state.monitorings.map(card => this.getCard(card)),
                this.state.pipelines.map(card => this.getCard(card)),
            ]
        } else {
            return this.state.projects.map(project => Object.values(project).map(cards => cards.map(card => this.getCard(card))))
        }
    }

    get managementMode() {
        return localStorage.getItem('managementMode') !== null
    }

    toggleManagementMode = () => {
        if (localStorage.getItem('managementMode') === null) {
            localStorage.setItem('managementMode', true)
        } else {
            localStorage.removeItem('managementMode');
        }
        this.setState({managementMode: !this.state.managementMode})
    }

    render() {
        return (
            <>
                <div className="content">
                    {this.cards.map((group, index) => {
                        return (
                            <div className='cards' key={index}>
                                {group}
                            </div>
                        )
                    })}
                </div>
                <button className={'btn btn' + (this.state.managementMode ? '--active' : '--inactive')} onClick={this.toggleManagementMode}>Management Mode</button>
            </>
        );
    }
}

export default App;
