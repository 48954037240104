import React from 'react';
import ReactInterval from 'react-interval';

export class AbstractModule extends React.Component {
    _isMounted = false;

    componentDidMount() {
        this.setState({status: ''})
        this.update()
    }

    async update() {
        fetch(this.apiUrl)
            .then(res => res.json())
            .then(res => this.handleResponse(res))
            .catch(error => { console.log(error)})
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <>
                <ReactInterval timeout="30000" enabled="true" callback={() => this.update()}/>
                {this.card}
            </>
        )
    }
}
